<template>
	<div class="h-full">
		<fixed-list :dataSource="dataSource" :config="config" @onDataSourceChange="onDataSourceChange"
			@onPageChange="onPageChange" :queryParam="queryParam">

			<div slot="buttonGroup">
				<div class="h-handle-button">
					<div class="h-b"><el-button type="primary" size="small" @click="batchsetwarnvalue"
							v-right-code="'Customer_Right_Stock_BranchWarehouseWarn_SetBranchWarehouseWarnConfig'">批量设置预警值</el-button>
					</div>

				</div>
			</div>
			<el-row slot="reserve1" class="h-table-choose">
				<el-button i type="text" @click="showlist(1)"
					v-bind:class="1==queryParam.PageParams.warnstatus? 'activebtn':''">全部（{{SearchCountData.TotalCount}}）</el-button>
				<el-button type="text" @click="showlist(2)"
					v-bind:class="2==queryParam.PageParams.warnstatus? 'activebtn':''">未设置（{{SearchCountData.NoSetCount}}）</el-button>
				<el-button type="text" @click="showlist(3)"
					v-bind:class="3==queryParam.PageParams.warnstatus? 'activebtn':''">已设置（{{SearchCountData.SetCount}}）</el-button>
				<el-button type="text" @click="showlist(4)"
					v-bind:class="4==queryParam.PageParams.warnstatus? 'activebtn':''">下限预警（{{SearchCountData.LowerWarnCount}}）</el-button>
				<el-button type="text" @click="showlist(5)"
					v-bind:class="5==queryParam.PageParams.warnstatus? 'activebtn':''">上限预警（{{SearchCountData.UpperWarnCount}}）</el-button>
			</el-row>
			<p slot="elList">
				<el-table class="tb-edit" slot="elList" ref="fullwarehousewarnListTable" :data="dataSource.Result"
					border @selection-change="handleSelectionChange" @current-change="handleCurrentChange"
					highlight-current-row>
					<el-table-column type="selection" width="55" v-if="!config.isDetailDisplay"></el-table-column>
					<el-table-column v-for="(col,index,count)  in dataSource.ColDefs.BodyFieldParams" :key="index"
						:prop="col.FieldName" :label="col.DisplayName"
						:render-header="bindFilter(queryParam,col,ignoreField)"
						:fixed="index==0&&(!config||!config.isDetailDisplay)"
						:min-width="(!config||!config.isDetailDisplay)?parseInt(col.Width):0"
						v-if="col.Visible&&(!config||!config.isDetailDisplay||(config.isDetailDisplay&&index<2))">
						<template slot-scope="scope">
							<el-row>
								<!--<el-tooltip class="item" effect="light" content="注：库存上限不能小于库存下限" placement="top">
    </el-tooltip>-->
								<el-input type="number" size="small" v-if="col.FieldName==='StockUpperLimit'"
									v-model="scope.row.StockUpperLimit" placeholder="请输入库存上限"
									@change="handleEdit(scope.$index, scope.row)"
									v-on:blur="losefocus(scope.$index, scope.row)"></el-input>

								<el-input type="number" size="small" v-if="col.FieldName==='StockLowerLimit'"
									v-model="scope.row.StockLowerLimit" placeholder="请输入库存下限"
									@change="handleEdit(scope.$index, scope.row)"
									v-on:blur="losefocus(scope.$index, scope.row)"></el-input>
								<span v-if="col.FieldName!=='StockUpperLimit'&&col.FieldName!=='StockLowerLimit'">
									{{ scope.row[col.FieldName] }}</span>
							</el-row>
						</template>
					</el-table-column>
				</el-table>
			</p>
		</fixed-list>
		<el-dialog title="批量设置预警值" :visible.sync="BatchSetWarnTemp.visible">
			<el-form label-width="80px">
				<el-form-item label="库存下限:">
					<el-input type="number" v-model="BatchSetWarnData.StockLowerLimit" :maxlength="120"></el-input>
				</el-form-item>
				<el-form-item label="库存上限:">
					<el-input type="number" v-model="BatchSetWarnData.StockUpperLimit" :maxlength="120"></el-input>
				</el-form-item>
			</el-form>
			<span slot="footer" class="dialog-footer">
				<el-button @click="BatchSetWarnTemp.visible = false">取 消</el-button>
				<el-button type="primary" @click="savewarnset">确 定</el-button>
			</span>
		</el-dialog>
	</div>
</template>
<script>
	export default {
		mounted() {
			this.Utils.lazy(() => {
				var _this = this;
				this.Event.$on("onRefreshList", () => this.initialize());
			});
			this.initialize();
		},
		data() {
			return {
				ignoreField: ["SKU", "OuterID", "ProductCode", "ProductWholeUnit"],
				//tableHeight: window.innerHeight-350,
				multipleSelection: [],
				queryParam: {
					PageIndex: 1,
					PageSize: 10,
					PageParams: {
						warehouseid: '',
						//warnstock: '1',
						warnstatus: 1
					}
				},
				dataSource: {
					ColDefs: {},
					Result: [],
					TotalCount: 0
				},
				warnconfigdata: {
					WarnID: '',
					ProductNo: '',
					InventoryType: '',
					WarehouseNo: '',
					StockUpperLimit: null,
					StockLowerLimit: null
				},
				BatchSetWarnTemp: {
					visible: false,
					size: 'small',
				},
				BatchSetWarnData: {
					ProductInfos: [],
					StockUpperLimit: null,
					StockLowerLimit: null
				},
				SearchCountData: {
					TotalCount: '',
					NoSetCount: '',
					SetCount: '',
					LowerWarnCount: '',
					UpperWarnCount: ''
				}
			}
		},
		props: {
			config: {
				isDetailDisplay: false,
				isButtonlDisplay: false,
			},
			option: {}
		},
		methods: {
			savewarnset() {
				var _this = this;
				var stockUpperLimit = _this.BatchSetWarnData.StockUpperLimit;
				var stockLowerLimit = _this.BatchSetWarnData.StockLowerLimit;
				if ((stockUpperLimit == null || stockUpperLimit == "") && (stockLowerLimit == null || stockLowerLimit ==
						"")) {
					this.Utils.messageBox("请至少设置一个库存限度");
					return false;
				}
				if (stockUpperLimit != null && stockUpperLimit != "" && stockLowerLimit != null && stockLowerLimit != "" &&
					Number(stockUpperLimit) < Number(stockUpperLimit)) {
					this.Utils.messageBox("库存上限不能小于库存下限");
					return false;
				}
				//batchsetfullwarehousewarnconfig
				_this.$ajax.send("omsapi/stockwarehousewarn/batchsetbranchwarehousewarnconfig", "post", _this
					.BatchSetWarnData, (data) => {
						if (data.IsSuccess == true) {
							_this.Utils.messageBox("批量设置成功.", "success");
							_this.BatchSetWarnTemp.visible = false;
							_this.reloadPageList();
						} else {
							this.Utils.messageBox(data.OperationDesc);
						}
					});
			},
			batchsetwarnvalue: function() {
				var _this = this;
				if (this.multipleSelection.length <= 0) {
					this.Utils.messageBox("请至少选中一个商品进行预警设置");
					return;
				}
				_this.BatchSetWarnData = {};
				var arr = [];
				for (var i = 0; i < this.multipleSelection.length; i++) {
					arr.push({
						WarnID: this.multipleSelection[i].WarnID,
						WarehouseNo: this.multipleSelection[i].WarehouseNo,
						ProductNo: this.multipleSelection[i].ProductNo,
						InventoryType: this.multipleSelection[i].InventoryType,
						CustomerCode: this.multipleSelection[i].CustomerCode
					});

				}
				_this.BatchSetWarnData.ProductInfos = arr;
				_this.BatchSetWarnTemp.visible = true;
			},
			onSetOk() {

			},
			showlist: function(index) {
				this.queryParam.PageParams.warnstatus = index;
				this.reloadPageList();
			},
			handleEdit(index, row) {},
			losefocus(index, row) {
				var _this = this;
				var stockUpperLimit = row.StockUpperLimit;
				var stockLowerLimit = row.StockLowerLimit;
				if (stockUpperLimit < 0 || stockLowerLimit < 0) {
					_this.Utils.messageBox("库存需设置成正整数");
					row.StockUpperLimit = row.StockUpperLimitOld;
					row.StockLowerLimit = row.StockLowerLimitOld;
					return false;
				}
				if (stockUpperLimit != null && stockLowerLimit != null && Number(stockUpperLimit) < Number(
						stockLowerLimit)) {
					_this.Utils.messageBox("库存上限不能小于库存下限");
					row.StockUpperLimit = row.StockUpperLimitOld;
					row.StockLowerLimit = row.StockLowerLimitOld;
					return false;
				}
				/* _this.warnconfigdata.WarnID = row.WarnID;
				_this.warnconfigdata.ProductNo = row.ProductNo;
				_this.warnconfigdata.InventoryType = row.InventoryType;
				_this.warnconfigdata.WarehouseNo = row.WarehouseNo; */
				_this.warnconfigdata.ProductInfos = [];
				_this.warnconfigdata.ProductInfos.push({
					WarnID: row.WarnID,
					ProductNo: row.ProductNo,
					WarehouseNo: row.WarehouseNo,
					InventoryType: row.InventoryType,
					CustomerCode: row.CustomerCode
				});
				_this.warnconfigdata.StockUpperLimit = row.StockUpperLimit;
				_this.warnconfigdata.StockLowerLimit = row.StockLowerLimit;
				_this.$ajax.send("omsapi/stockwarehousewarn/batchsetbranchwarehousewarnconfig", "post", _this
					.warnconfigdata, (
						data) => {
						if (data.IsSuccess == true) {
							row.WarnID = data.Result;
							_this.Utils.messageBox("设置成功.", "success");
							row.StockUpperLimitOld = row.StockUpperLimit;
							row.StockLowerLimitOld = row.StockLowerLimit;
							_this.reloadPageList();

						} else {
							row.StockUpperLimit = row.StockUpperLimitOld;
							row.StockLowerLimit = row.StockLowerLimitOld;
							console.log("设置失败");
							this.Utils.messageBox(data.OperationDesc);

						}
					});
			},
			reloadPageList: function() {
				this.onPageChange(this.queryParam);
				this.getsearchcountdata(this.queryParam);
			},
			initialize() {
				this.onPageChange(this.queryParam);
				this.getsearchcountdata(this.queryParam);
			},
			handleCurrentChange: function(val) {
				this.$emit("onSelectedRow", val);
			},
			handleSelectionChange(val) {
				this.multipleSelection = val;
			},
			onPageChange(param) {
				var _this = this;
				this.queryParam = param;
				this.$ajax.query("omsapi/stockwarehousewarn/searchbranchwarehousewarnlist", "post", param, data => {
					_this.dataSource = data;
				});
			},
			getsearchcountdata(param) {
				var _this = this;
				this.queryParam = param;
				this.$ajax.query("omsapi/stockwarehousewarn/searchbranchwarehousewarncount", "post", param, data => {
					_this.SearchCountData = data.Result;
				});
			},
			onDataSourceChange(ds) {
				var _this = this;

				_this.dataSource = {
					ColDefs: {
						BodyFieldParams: []
					},
					Result: [],
					TotalCount: 0
				};
				_this.$nextTick(function() {
					_this.dataSource = ds;

				});
			}
		}
	}
</script>
<style>
</style>